import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";

type Props = {
  wallet: React.ReactNode;
  marquee: React.ReactNode;
  bannerCarousel: React.ReactNode;
  gameCategoryMenu: React.ReactNode;
  mainMenu: React.ReactNode;
  depositAndWithdrawButtonGroup?: React.ReactNode;
};

export default function LayoutDashboard({
  wallet,
  marquee,

  bannerCarousel,
  gameCategoryMenu,
  mainMenu,
  depositAndWithdrawButtonGroup,
}: Props) {
  const theme = useTheme();
  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* <Grid item xs={12}>
          {marquee}
        </Grid> */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {bannerCarousel}
        </Grid>
        {/* <Grid item xs={12} md={6}>
          {wallet}
        </Grid>
        <Grid item xs={12} md={6}>
          {mainMenu}
        </Grid> */}
        {/* <Grid item xs={12} md={12}>
          {gameCategoryMenu}
        </Grid> */}
      </Grid>
    </Box>
  )
}
